const data = [
  {
    title: "iOS App Development",
    description: "Our team of skilled iOS developers specializes in creating custom applications for iPhone, iPad, and Apple Watch. We use Swift and Objective-C programming languages to develop native apps that deliver an exceptional user experience. We have extensive experience in developing apps for various industries such as healthcare, finance, retail, and more.",
  },
  {
    title: "Android App Development",
    description: "Our team of experienced Android developers creates custom applications that work seamlessly across all Android devices, including smartphones and tablets. We use Java and Kotlin programming languages to develop native apps that provide a smooth and responsive user experience. We have extensive experience in developing apps for various industries such as healthcare, finance, retail, and more.",
  },
  {
    title: "Cross-Platform App Development",
    description: "With the increasing demand for cross-platform apps, our team has expertise in popular cross-platform development frameworks such as React Native, Xamarin, and Flutter. We can create high-quality, feature-rich applications that work seamlessly across iOS and Android devices, saving you time and development costs.",
  },
  {
    title: "Mobile App Design",
    description: "Our team of talented designers creates engaging and user-friendly designs for mobile applications. We use a customer-centric approach to create designs that align with your business goals and meet the needs of your users. Our design process includes user research, wireframing, prototyping, and user testing to ensure that the final product meets your expectations.",
  },
  {
    title: "App Maintenance and Support",
    description: "Our app maintenance and support services ensure that your application runs smoothly and is up-to-date with the latest technologies. We provide regular updates, bug fixes, and feature enhancements to keep your app relevant and competitive. Our support team is available 24/7 to handle any issues that may arise.",
  },
];

export default data;