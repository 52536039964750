// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function Footer() {
	return (
		<MKBox component="footer" py={6}>
			<Container>
				<Grid container>
					<Grid
						item
						xs={12}
						lg={4}
						textAlign={{ xs: "center", lg: "left" }}
						mr="auto"
						mb={{ xs: 3, lg: 0 }}
					>
						<MKTypography variant="h5" textTransform="uppercase" mb={{ xs: 2, lg: 3 }}>
							Dream Tech LLC
						</MKTypography>
						<MKTypography variant="body2" mb={{ xs: 2, lg: 3 }}>
							Based in Provo, UT
						</MKTypography>
						<Stack
							component="ul"
							direction="row"
							flexWrap="wrap"
							spacing={3}
							justifyContent={{ xs: "center", lg: "flex-start" }}
							pl={0}
							mb={3}
							sx={{ listStyle: "none" }}
						>
							<MKBox component="li">
								<MKTypography
									variant="button"
									fontWeight="regular"
									opacity={0.8}
									component={Link}
									href="/"
									target="_self"
									rel="noreferrer"
								>
									Home
								</MKTypography>
							</MKBox>
							<MKBox component="li">
								<MKTypography
									variant="button"
									fontWeight="regular"
									opacity={0.8}
									component={Link}
									href="/about"
									target="_self"
									rel="noreferrer"
								>
									About
								</MKTypography>
							</MKBox>
							<MKBox component="li">
								<MKTypography
									variant="button"
									fontWeight="regular"
									opacity={0.8}
									component={Link}
									href="/contact"
									target="_self"
									rel="noreferrer"
								>
									Contact
								</MKTypography>
							</MKBox>
							<MKBox component="li">
								<MKTypography
									variant="button"
									fontWeight="regular"
									opacity={0.8}
									component={Link}
									href="/privacy-policy"
									target="_self"
									rel="noreferrer"
								>
									Privacy Policy
								</MKTypography>
							</MKBox>
							<MKBox component="li">
								<MKTypography
									variant="button"
									fontWeight="regular"
									opacity={0.8}
									component={Link}
									href="/blog"
									target="_self"
									rel="noreferrer"
								>
									Blog
								</MKTypography>
							</MKBox>
						</Stack>
						<MKTypography variant="button" opacity={0.8}>
							All Rights Reserved. Copyright © {new Date().getFullYear()} Dream
							Tech LLC.
						</MKTypography>
					</Grid>
					<Grid item xs={12} lg={6} ml="auto" textAlign={{ xs: "center", lg: "right" }}>
						<MKTypography variant="body1" fontWeight="bold" mb={6} sx={{ fontSize: "1.125rem" }}>
							Empowering your vision with custom software solutions
						</MKTypography>
					</Grid>
				</Grid>
			</Container>
		</MKBox>
	);
}

export default Footer;
