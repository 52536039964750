// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React components
import TransparentBlogCard from "examples/Cards/BlogCards/TransparentBlogCard";
import BackgroundBlogCard from "examples/Cards/BlogCards/BackgroundBlogCard";

// Image
import card4 from "assets/images/examples/blog2.jpg";

// Data
import cards from "./data/otherServicesData";

function OtherServices() {
  return (
    <MKBox component="section" py={2}>
      <Container>
        <Grid container item xs={12} lg={6}>
          <MKTypography variant="h3" mb={6}>
            Check out our other services
          </MKTypography>
        </Grid>
        <Grid container spacing={3}>
          {cards.map((card, index) => (
            <Grid key={index} item xs={12} sm={6} lg={3}>
              <TransparentBlogCard
                image={card.image}
                title={card.title}
                description={card.description}
                action={card.action}
              />
            </Grid>
          ))}
          <Grid item xs={12} sm={6} lg={3}>
            <BackgroundBlogCard
              image={card4}
              title="Get Started Today"
              description="Transform your needs into reality with our expert software development services tailored to your unique needs."
              action={{
                type: "external",
                route: "https://calendly.com/dreamtechlab",
                label: "Schedule Consultation",
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default OtherServices;
