// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "../../../components/MKTypography";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

// page sections
import OtherServices from "pages/ServicesPages/SoftwareDevelopment/sections/OtherServices";
import Footer from "components/Footer";
import FormSimple from "layouts/sections/input-areas/forms/components/FormSimple";
import BuiltByDevelopers from "./sections/BuiltByDevelopers";

// Data
import data from "./sections/data/servicesData";

// Routes
import routes from "routes";

// Images
import bgImage from "assets/images/bg-intellij.png";

function SoftwareDevelopment() {
  const renderData = data.map(({ title, description, items }, index) => (
    <Grid container spacing={3} sx={{ mb: 10 }} key={title}>
      <Grid item xs={12} lg={3}>
        <MKBox position="sticky" top="100px" pb={{ xs: 2, lg: 6 }}>
          <MKTypography variant="h3" fontWeight="bold" mb={1}>
            {title}
          </MKTypography>
        </MKBox>
      </Grid>
      <Grid item xs={12} lg={9}>
        <Grid container spacing={3}>
          <MKTypography variant="body1" fontWeight="regular" color="secondary" mb={1} pr={2}>
            {description}
          </MKTypography>
        </Grid>
      </Grid>
    </Grid>
  ));

  return (
    <>
      <DefaultNavbar
        routes={routes}
        sticky
      />
      <MKBox bgColor="white">
        <MKBox
          minHeight="25rem"
          width="100%"
          sx={{
            backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
              `${linearGradient(
                rgba(gradients.dark.main, 0.8),
                rgba(gradients.dark.state, 0.8)
              )}, url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "grid",
            placeItems: "center",
          }}
        />
        <Card
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mt: -8,
            mb: 4,
            backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
            backdropFilter: "saturate(200%) blur(30px)",
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
          }}
        >
          <Container sx={{ mt: 6 }}>
            <BuiltByDevelopers />
            <Container sx={{ mt: 12 }}>{renderData}</Container>
          </Container>
          <OtherServices />
          <FormSimple />
        </Card>
        <Footer />
      </MKBox>
    </>
  );
}

export default SoftwareDevelopment;
