// @mui material components
import Icon from "@mui/material/Icon";
import SoftwareDevelopment from "./pages/ServicesPages/SoftwareDevelopment";
import WebDevelopment from "./pages/ServicesPages/WebDevelopment";
import ContactUs from "./pages/ContactUsPage";
import AppDevelopment from "./pages/ServicesPages/AppDevelopment";
import UIUXDesign from "./pages/ServicesPages/UIUXDesign";
import GameDevelopment from "./pages/ServicesPages/GameDevelopment";
import PricingPage from "./pages/PricingPage";
import AboutUsPage from "./pages/AboutUsPage";
import BlogPage from "./pages/BlogPage";

const routes = [
  {
    name: "services",
    icon: <Icon>build</Icon>,
    collapse: [
      {
        name: "Software Development",
        description: "Innovative software solutions tailored to your needs",
        route: "/software-development",
        component: <SoftwareDevelopment />,
      },
      {
        name: "Web Development",
        description: "Modern, responsive websites that captivate your audience",
        route: "/web-development",
        component: <WebDevelopment />,
      },
      {
        name: "App Development",
        description: "Custom mobile apps that deliver seamless experiences",
        route: "/app-development",
        component: <AppDevelopment />,
      },
      {
        name: "UI/UX Design",
        description:
          "User-centric design that enhances usability and engagement",
        route: "/uiux-design",
        component: <UIUXDesign />,
      },
      {
        name: "Game Development",
        description:
          "Engaging games that immerse players in captivating worlds",
        route: "/game-development",
        component: <GameDevelopment />,
      },
    ],
  },
  {
    name: "pricing",
    icon: <Icon>sell</Icon>,
    route: "/pricing",
    component: <PricingPage />,
  },
  {
    name: "about us",
    icon: <Icon>people</Icon>,
    route: "/about",
    component: <AboutUsPage />,
  },
  {
    name: "blog",
    icon: <Icon>chat</Icon>,
    route: "/blog",
    component: <BlogPage />,
  },
  {
    name: "contact us",
    icon: <Icon>email</Icon>,
    route: "/contact",
    component: <ContactUs />,
  },
];

export default routes;
