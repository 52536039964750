const data = [
  {
    title: "Agile Software Development",
    description: "At Dream Tech, we specialize in agile software development that leverages the latest technologies and frameworks to deliver scalable, high-quality applications. Our iterative development process allows us to quickly adapt to changes and incorporate customer feedback, ensuring that the final product meets your unique requirements. We also use rapid prototyping and testing to minimize risk and accelerate time-to-market.",
  },
  {
    title: "Full-Stack Development",
    description: "Our team of experienced developers is proficient in both front-end and back-end technologies, allowing us to create responsive, feature-rich applications that meet your specific needs. We use HTML, CSS, and JavaScript to create dynamic user interfaces, while Node.js, Python, and Ruby on Rails power the back-end. Our expertise in database technologies such as MySQL, PostgreSQL, and MongoDB allows us to create data-driven applications that scale with your business.",
  },
  {
    title: "Mobile App Development",
    description: "With the increasing demand for mobile applications, our team has extensive experience in mobile app development. We can create cross-platform applications using popular frameworks such as React Native, Xamarin, and Flutter, allowing your app to work seamlessly across iOS and Android devices. Alternatively, we can create native apps using platform-specific technologies to provide a superior user experience.",
  },
  {
    title: "Cloud-Native Applications",
    description: "As businesses move towards the cloud, our expertise in cloud-native applications can help you achieve greater agility, scalability, and cost savings. We use cloud infrastructure such as AWS, Azure, and Google Cloud to build applications that are designed to run in the cloud. Our microservices architecture and serverless computing approach allows us to create modular and scalable applications that can be easily deployed and managed.",
  },
  {
    title: "Emerging Technologies",
    description: "At Dream Tech, we stay up-to-date with the latest developments in emerging technologies such as machine learning, artificial intelligence, big data, and blockchain development. Our experience in Python, TensorFlow, Keras, and other machine learning frameworks allows us to create applications with advanced features such as natural language processing, image recognition, and predictive analytics. We can also help you harness the power of Hadoop, Spark, Cassandra, and other big data technologies to process and analyze large volumes of data. In addition, our expertise in Ethereum, Solidity, and Smart Contracts enables us to create secure and decentralized applications using blockchain technology.",
  },
];

export default data;