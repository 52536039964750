import { useState } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import {InlineWidget} from "react-calendly";

function FormSimple() {
  const [checked, setChecked] = useState(true);

  const handleChecked = () => setChecked(!checked);

  return (
    <MKBox component="section" py={12}>
      <Container id="schedule">
        <Grid container item justifyContent="center" xs={10} lg={7} mx="auto" textAlign="center">
          <MKTypography variant="h1" mb={1}>
            Schedule a no-commitment consultation call today!
          </MKTypography>
          <MKTypography variant="body1">
            *Your privacy and security are our top priority. All consultations are confidential and conducted under an NDA.
          </MKTypography>
        </Grid>
        <InlineWidget
          url="https://calendly.com/dreamtechlab/30min"
          styles={{
            height: "100vh",
            width: "100%",
            position: "relative",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0
          }}
        />
      </Container>
    </MKBox>
  );
}

export default FormSimple;
