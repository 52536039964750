// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "../../../components/MKTypography";

// Material Kit 2 React examples
import BuiltByDevelopers from "../sections/BuiltByDevelopers";

// Images

function Information() {
  return (
    <MKBox component="section" py={6} my={6}>
      <Container>
        <Grid container item xs={11} spacing={3} alignItems="center" sx={{ mx: "auto" }}>
          <Grid item xs={12} lg={7} sx={{ ml: "auto" }}>
              <MKTypography variant="h3" fontWeight="bold" mb={5}>
                  Pricing
              </MKTypography>
              <MKTypography variant="h4" fontWeight="regular" mb={5}>
                  Based in Utah, we are proud to serve clients throughout the state and beyond. We take pride in our commitment to delivering high-quality services at affordable prices. Our hourly rate of $50 is competitive, and we offer flexible payment options to meet your budget and needs.
              </MKTypography>
              <MKTypography variant="h4" fontWeight="regular">
                  Contact us today to learn more about our services and how we can help take your business to the next level!
              </MKTypography>
          </Grid>
          <Grid item xs={12} lg={4} sx={{ mx: "auto" }}>
              <BuiltByDevelopers />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
