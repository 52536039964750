// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import MKTypography from "components/MKTypography";

import HorizontalTeamCard from "../../../examples/Cards/TeamCards/HorizontalTeamCard";

import drewImage from "assets/images/drew.jpeg";

function Team() {
  return (
    <MKBox component="section" pt={12}>
      <Container>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} lg={12}>
            <Grid container justifyContent="flex-start">
              <MKTypography variant="h1" mb={7}>
                Our team
              </MKTypography>
              <HorizontalTeamCard
                image={drewImage}
                description="Drew Bayles, the Founder of Dream Tech, is a highly competent and skilled software engineer with a Bachelor of Science in Computer Science; Software Engineering from Brigham Young University, where he also earned a minor in Africana Studies. With years of experience under his belt, Drew has developed a strong reputation for excellence in his field.

As a seasoned software engineer, Drew has honed his skills through a variety of software engineering positions. He has a proven track record of full-stack development, data engineering, and has proven his ability to effectively manage teams of software engineers using agile principles to beat tight deadlines. With his expertise, Drew is dedicated to providing the highest quality software engineering services to his clients through Dream Tech."
                position={{ color: "info", label: "Founder" }}
                name="Drew Bayles"
              />
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Team;
