import { Link } from "react-router-dom";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

// Navbar and Footer
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import Footer from "components/Footer";

// page sections
import Information from "./sections/Information";
import Team from "./sections/Team";
import FormSimple from "../../layouts/sections/input-areas/forms/components/FormSimple";

// Routes
import routes from "routes";

// Images
import bgImage from "assets/images/bg-clouds-min.jpg";

function AboutUsPage() {
  return (
    <>
      <DefaultNavbar
        routes={routes}
        sticky
      />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
              mb={3}
            >
              Empowering businesses and individuals through innovative technology solutions
            </MKTypography>
            <Grid container spacing={2} direction={{ xs: 'column', md: 'row' }} sx={{ justifyContent: 'center' }}>
              <Grid item>
                <MKButton
                  color="default"
                  component={Link}
                  to="https://calendly.com/dreamtechlab"
                  target="_blank"
                  size="large"
                  sx={{
                    color: ({ palette: { dark } }) => dark.main,
                    "&:hover": {
                      backgroundColor: ({ palette: { info } }) => info.main,
                      color: ({ palette: { light } }) => light.main,
                    },
                  }}
                >
                  Schedule Consultation
                </MKButton>
              </Grid>
              <Grid item>
                <MKButton
                  color="default"
                  component={Link}
                  to="/contact"
                  size="large"
                  sx={{
                    color: ({ palette: { dark } }) => dark.main,
                    "&:hover": {
                      backgroundColor: ({ palette: { info } }) => info.main,
                      color: ({ palette: { light } }) => light.main,
                    },
                  }}
                >
                  Contact Us
                </MKButton>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Information
          title="Our story"
          bodyTexts={
            [
              "Dream Tech was founded in 2023 by a team of passionate individuals dedicated to providing high-quality web and app development, SEO, marketing, and design services to local Utah County businesses. Despite starting out in a humble basement office and relying solely on word-of-mouth, we have already made a name for ourselves in the industry through our commitment to excellence and client satisfaction.",
              "While we may not have worked with any Fortune 500 companies (yet!), we take great pride in supporting the growth and success of our fellow small businesses. We understand the unique challenges that come with operating a local business, and we strive to be a reliable partner in navigating the digital landscape.",
              "As we continue to expand and evolve, we remain committed to our core values of transparency, innovation, and customer-centricity. Our team is passionate about what we do, and we are excited to help our clients achieve their goals through technology and creativity."
            ]
          }/>
        <Team />
        <FormSimple />
      </Card>
      <Footer />
    </>
  );
}

export default AboutUsPage;
