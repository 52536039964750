import { Link } from "react-router-dom";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

// Navbar and Footer
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import Footer from "components/Footer";

// page sections
import FormSimple from "../../layouts/sections/input-areas/forms/components/FormSimple";

// Routes
import routes from "routes";

// Images
import bgImage from "assets/images/bg-clouds-min.jpg";
import blogPosts from "./data/blogPostsData";
import TransparentBlogCard from "../../examples/Cards/BlogCards/TransparentBlogCard";

function BlogPage() {
  return (
    <>
      <DefaultNavbar routes={routes} sticky />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: ({
            functions: { linearGradient, rgba },
            palette: { gradients },
          }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
              mb={3}
            >
              Blog
            </MKTypography>
            <Grid
              container
              spacing={2}
              direction={{ xs: "column", md: "row" }}
              sx={{ justifyContent: "center" }}
            >
              <Grid item>
                <MKButton
                  color="default"
                  component={Link}
                  to="https://calendly.com/dreamtechlab"
                  target="_blank"
                  size="large"
                  sx={{
                    color: ({ palette: { dark } }) => dark.main,
                    "&:hover": {
                      backgroundColor: ({ palette: { info } }) => info.main,
                      color: ({ palette: { light } }) => light.main,
                    },
                  }}
                >
                  Schedule Consultation
                </MKButton>
              </Grid>
              <Grid item>
                <MKButton
                  color="default"
                  component={Link}
                  to="/contact"
                  size="large"
                  sx={{
                    color: ({ palette: { dark } }) => dark.main,
                    "&:hover": {
                      backgroundColor: ({ palette: { info } }) => info.main,
                      color: ({ palette: { light } }) => light.main,
                    },
                  }}
                >
                  Contact Us
                </MKButton>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Container>
          <Grid item xs={12} pt={12}>
            <MKTypography variant="h1" mb={2}>
              Check out our blog posts
            </MKTypography>
          </Grid>
          <Grid container spacing={3} mt={3}>
            {blogPosts.map((post, index) => (
              <Grid key={index} item xs={12} sm={6} lg={3}>
                <Link to={`/blog/${index}`}>
                  <TransparentBlogCard
                    image={post.image}
                    title={post.title}
                    date={post.date}
                    description={post.description}
                    action={{
                      type: "internal",
                      route: `/blog/${index}`,
                      color: "info",
                      label: "Read More",
                    }}
                  />
                </Link>
              </Grid>
            ))}
          </Grid>
          <FormSimple />
        </Container>
      </Card>
      <Footer />
    </>
  );
}

export default BlogPage;
