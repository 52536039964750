import { Link } from "react-router-dom";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// images
import bgImage from "assets/images/desktop.jpg";

// text
import {
	TEXT_H4,
	TEXT_H5,
} from "./data/builtByDevelopersData.js";

function BuiltByDevelopers() {
	return (
		<MKBox
			display="flex"
			alignItems="center"
			borderRadius="xl"
			my={2}
			py={6}
			sx={{
				backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
					`${linearGradient(
						rgba(gradients.dark.main, 0.8),
						rgba(gradients.dark.state, 0.8)
					)}, url(${bgImage})`,
				backgroundSize: "cover",
				backgroundPosition: "center",
			}}
		>
			<Container>
				<Grid container item xs={12} lg={9} sx={{ ml: { xs: 0, lg: 6 } }}>
					<MKTypography variant="h4" color="white" fontWeight="bold">
						{TEXT_H4}
					</MKTypography>
					<br />
					<br />
					<MKTypography variant="h5" color="white" mb={1}>
						{TEXT_H5}
					</MKTypography>
					<MKTypography
						component={Link}
						to="#schedule"
						variant="body2"
						color="white"
						fontWeight="regular"
						sx={{
							display: "flex",
							alignItems: "center",

							"& .material-icons-round": {
								fontSize: "1.125rem",
								transform: `translateX(3px)`,
								transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
							},

							"&:hover .material-icons-round, &:focus .material-icons-round": {
								transform: `translateX(6px)`,
							},
						}}
						onClick={() => {
							const scheduleElement = document.getElementById('schedule');
							scheduleElement.scrollIntoView({ behavior: "smooth" });
						}}
					>
						Schedule a consultation today <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
					</MKTypography>
				</Grid>
			</Container>
		</MKBox>
	);
}

export default BuiltByDevelopers;
