// Images
import card1 from "assets/images/products/web-development-card.jpg";
import card2 from "assets/images/products/app-development-card.jpg";
import card3 from "assets/images/products/game-development-card.jpg";

const cards = [
	{
		image: card1,
		title: 'Web Development',
		description: 'Creating an engaging and responsive website shouldn\'t be rocket science. That\'s why we believe in simplifying the web development process ...',
		action: {
			type: 'internal',
			route: '/web-development',
			color: 'info',
			label: 'Learn more',
		},
	},
	{
		image: card2,
		title: 'App Development',
		description: 'Need an app that simplifies your life? Whether it\'s organizing your schedule, managing your finances ...',
		action: {
			type: 'internal',
			route: '/app-development',
			color: 'info',
			label: 'Learn more',
		},
	},
	{
		image: card3,
		title: 'Game Development',
		description: 'From action-packed adventures to immersive story-driven experiences, games have the power to transport us to new worlds and provide endless hours of entertainment ...',
		action: {
			type: 'internal',
			route: '/game-development',
			color: 'info',
			label: 'Learn more',
		},
	},
];

export default cards;