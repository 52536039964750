import musiclight from "assets/images/portfolio/musiclight.png";
import comdevnet from "assets/images/portfolio/comdevnet.png";
import calltoaction from "assets/images/portfolio/call-to-action.png";

const data = [
  {
    image: musiclight,
    name: "MusicLight",
    route: "https://www.musiclight.org/",
  },
  {
    image: comdevnet,
    name: "Community Development Network",
    route: "https://www.comdevnet.org/",
  },
  {
    image: calltoaction,
    name: "Click here to get started today",
    route: "https://calendly.com/dreamtechlab",
  },
];

export default data;