import { Link } from "react-router-dom";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

// Navbar and Footer
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import Footer from "components/Footer";

// page sections
import Information from "pages/LandingPage/sections/Information";
import FormSimple from "../../layouts/sections/input-areas/forms/components/FormSimple";
import Testimonials from "pages/LandingPage/sections/Testimonials";
import Portfolio from "pages/LandingPage/sections/Portfolio";
import ProcessTimeline from "pages/LandingPage/sections/ProcessTimeline";

// Routes
import routes from "routes";

// Images
import bgImage from "assets/images/bg-clouds-min.jpg";

function LandingPage() {
  return (
    <>
      <DefaultNavbar
        routes={routes}
        sticky
      />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Custom Software Development Services
            </MKTypography>
            <MKTypography variant="body1" color="white" mt={1} mb={3}>
              Our team of experienced developers and designers work with businesses of all sizes to develop tailored
              software solutions that meet their specific needs. We offer a range of software development services,
              including web and app development, SEO and marketing, and more.
            </MKTypography>
            <MKButton
              color="default"
              component={Link}
              to="https://calendly.com/dreamtechlab"
              target="_blank"
              size="large"
              sx={{
                color: (
                  { palette: { dark } }) => dark.main,
                "&:hover": {
                  backgroundColor: ({ palette: { info } }) => info.main,
                  color: ({ palette: { light } }) => light.main,
                },
              }}
            >
              Schedule Consultation
            </MKButton>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Information />
        <Portfolio />
        <Testimonials />
        <ProcessTimeline />
        <FormSimple />
      </Card>
      <Footer />
    </>
  );
}

export default LandingPage;
