import {useRef, useState} from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import Footer from "components/Footer";

import * as emailjs from "@emailjs/browser";

// Routes
import routes from "routes";

// Image
import bgImage from "assets/images/illustrations/illustration-reset.jpg";
import MKAlert from "../../components/MKAlert";

function ContactUs() {
  const [alert, setAlert] = useState(null);
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_fh4nmu7', 'template_81qs0nb', form.current, 'a2rJ_U9Rv8m5GdkiZ')
      .then((result) => {
        setAlert({ type: 'success', message: 'Message sent successfully. We will be in touch within 24 hours. Thank you!' });
      }, (error) => {
        setAlert({ type: 'error', message: (
            <div>
              Failed to send email. Please click <a href="https://calendly.com/dreamtechlab" target="_blank" rel="noreferrer">here</a> to schedule a meeting or try again later.
            </div>
          ), });
      });
  };

  return (
    <>
      <MKBox position="fixed" top="0rem" width="100%">
        <DefaultNavbar
          routes={routes}
        />
      </MKBox>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} lg={6}>
          <MKBox
            display={{ xs: "none", lg: "flex" }}
            width="calc(100% - 2rem)"
            height="calc(100vh - 2rem)"
            borderRadius="lg"
            ml={2}
            mt={2}
            sx={{ backgroundImage: `url(${bgImage})` }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={10}
          md={7}
          lg={6}
          xl={4}
          ml={{ xs: "auto", lg: 6 }}
          mr={{ xs: "auto", lg: 6 }}
        >
          <MKBox
            bgColor="white"
            borderRadius="xl"
            shadow="lg"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            mt={{ xs: 20, sm: 18, md: 20 }}
            mb={{ xs: 20, sm: 18, md: 20 }}
            mx={3}
          >
            <MKBox
              variant="gradient"
              bgColor="info"
              coloredShadow="info"
              borderRadius="lg"
              p={2}
              mx={2}
              mt={-3}
            >
              <MKTypography variant="h3" color="white">
                Contact us
              </MKTypography>
            </MKBox>
            <MKBox p={3}>
              {alert && (
                <MKAlert color={alert.type} onClose={() => setAlert(null)}>
                  {alert.message}
                </MKAlert>
              )}
              <MKTypography variant="body1" color="text" mb={3}>
                For further questions, including partnership opportunities, please email{' '}
                <a href="mailto:sales@dreamtechlab.com">sales@dreamtechlab.com</a> or contact using our contact
                form.
              </MKTypography>
              <MKBox width="100%" component="form" method="post" ref={form} onSubmit={sendEmail}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <MKInput
                      variant="standard"
                      label="First"
                      name="first_name"
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MKInput
                      variant="standard"
                      label="Last"
                      name="last_name"
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MKInput
                      type="email"
                      variant="standard"
                      label="Email"
                      name="email"
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MKInput
                      type="tel"
                      variant="standard"
                      label="Phone"
                      name="phone"
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <MKInput
                      variant="standard"
                      label="How can we help you?"
                      name="message"
                      placeholder="Describe your software needs here. We will send an NDA before meeting with you."
                      InputLabelProps={{ shrink: true }}
                      multiline
                      fullWidth
                      rows={6}
                    />
                  </Grid>
                </Grid>
                <Grid container item justifyContent="center" xs={12} mt={5} mb={2}>
                  <MKButton type="submit" variant="gradient" color="info">
                    Send Message
                  </MKButton>
                </Grid>
              </MKBox>
            </MKBox>
          </MKBox>
        </Grid>
      </Grid>
      <Footer />
    </>
  );
}

export default ContactUs;
