import DefaultNavbar from "../../examples/Navbars/DefaultNavbar";
import routes from "../../routes";
import MKTypography from "../../components/MKTypography";
import {Container} from "@mui/material";
import bgImage from "../../assets/images/bg-clouds-min.jpg";
import MKBox from "../../components/MKBox";

function ConfirmationPage() {
    return (
        <>
            <DefaultNavbar
                routes={routes}
                sticky
            />
            <MKBox
                minHeight="100vh"
                width="100%"
                sx={{
                    backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
                        `${linearGradient(
                            rgba(gradients.dark.main, 0.6),
                            rgba(gradients.dark.state, 0.6)
                        )}, url(${bgImage})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    display: "grid",
                    placeItems: "center",
                }}
            >
                <Container>
                    <MKTypography variant="h3" color="white">
                        Your meeting is <span style={{ color: "#34b233" }}>confirmed</span>. Please check your email for details, instructions, and an NDA.
                    </MKTypography>
                    <br />
                    <MKTypography variant="h3" color="white">
                        Thank you for choosing Dream Tech for your web and mobile development needs. We can't wait to work with you!
                    </MKTypography>
                </Container>
            </MKBox>
        </>
    )
}

export default ConfirmationPage;