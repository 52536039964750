// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "../../../components/MKTypography";

function Information() {
  return (
    <MKBox component="section" py={6} my={6}>
      <Container>
        <Grid container item xs={11} spacing={3} alignItems="center" sx={{ mx: "auto" }}>
          <Grid item xs={12}>
            <MKTypography variant="h4" fontWeight="regular" mb={5}>
              At Dream Tech, we take your privacy very seriously. This Privacy Policy explains how we collect, use, and protect your personal information.
            </MKTypography>
            <MKTypography variant="h3" fontWeight="bold" mb={5}>
              Collection of Personal Information
            </MKTypography>
            <MKTypography variant="h4" fontWeight="regular" mb={5}>
              We may collect personal information such as your name, email address, and phone number when you contact us through our website or engage with us on social media. We also collect information about your use of our website through cookies and similar technologies.
            </MKTypography>
            <MKTypography variant="h3" fontWeight="bold" mb={5}>
              Use of Personal Information
            </MKTypography>
            <MKTypography variant="h4" fontWeight="regular" mb={5}>
              We may use your personal information to respond to your inquiries, provide you with our services, and communicate with you about our products and services. We may also use your personal information for marketing purposes, such as sending you promotional emails or newsletters. We will only use your personal information for the purposes for which it was collected, unless we obtain your consent to use it for other purposes.
            </MKTypography>
            <MKTypography variant="h3" fontWeight="bold" mb={5}>
              Protection of Personal Information
            </MKTypography>
            <MKTypography variant="h4" fontWeight="regular" mb={5}>
              We take appropriate measures to protect your personal information from unauthorized access, disclosure, or alteration. We use industry-standard security technologies and procedures to protect your personal information. However, no method of transmission over the Internet or electronic storage is completely secure, and we cannot guarantee absolute security.
            </MKTypography>
            <MKTypography variant="h3" fontWeight="bold" mb={5}>
              Sharing of Personal Information
            </MKTypography>
            <MKTypography variant="h4" fontWeight="regular" mb={5}>
              We may share your personal information with third-party service providers who help us operate our business, such as website hosting, email communication, and payment processing. We will only share your personal information with these third-party service providers to the extent necessary to perform their services for us. We may also share your personal information when required by law or when we believe it is necessary to protect our rights, property, or safety.
            </MKTypography>
            <MKTypography variant="h3" fontWeight="bold" mb={5}>
              Your Rights and Choices
            </MKTypography>
            <MKTypography variant="h4" fontWeight="regular" mb={5}>
              You have the right to access and correct your personal information that we hold. You can also opt out of receiving promotional emails from us by following the instructions in the email or contacting us directly. Please note that even if you opt out of receiving promotional emails, we may still send you emails about your transactions with us or other important notices.
            </MKTypography>
            <MKTypography variant="h3" fontWeight="bold" mb={5}>
              Changes to Privacy Policy
            </MKTypography>
            <MKTypography variant="h4" fontWeight="regular" mb={5}>
              We may update this Privacy Policy from time to time. Any changes will be effective immediately upon posting the updated Privacy Policy on our website. Your continued use of our website after the updated Privacy Policy is posted constitutes your acceptance of the updated Privacy Policy.
            </MKTypography>
            <MKTypography variant="h3" fontWeight="bold" mb={5}>
              Contact Us
            </MKTypography>
            <MKTypography variant="h4" fontWeight="regular" mb={5}>
              If you have any questions or concerns about our Privacy Policy, please contact us via <a href="/contact">this form</a>.
            </MKTypography>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
