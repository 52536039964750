const data = [
  {
    title: "User Research and Analysis for Game Development",
    description: "We conduct thorough user research and analysis to understand your target audience and their needs, ensuring that we design user-centric interfaces that improve usability and provide a seamless gaming experience.",
  },
  {
    title: "Wireframing and Prototyping for Game Design",
    description: "Our design team creates wireframes and prototypes to provide a visual representation of the game interface and interactions. This allows us to refine the design before starting development, reducing the risk of costly changes later on and ensuring a successful gaming product.",
  },
  {
    title: "UI and Visual Design for Game Development",
    description: "Our UI and visual designers create engaging and aesthetically pleasing interfaces that align with your brand's visual identity. We use the latest design trends and technologies to create modern and intuitive interfaces that meet your gaming needs.",
  },
  {
    title: "Usability Testing for Game Design",
    description: "We conduct usability testing to validate our designs and ensure that they meet your users' needs. Our thorough testing process allows us to identify and fix any issues before launch, resulting in a more successful gaming product.",
  },
  {
    title: "Accessibility and Inclusivity for Game Development",
    description: "We design with accessibility and inclusivity in mind, ensuring that your game is usable and accessible to everyone, regardless of their abilities. We follow industry standards and guidelines to create games that are accessible and meet the needs of a diverse audience.",
  },
];

export default data;