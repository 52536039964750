// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultReviewCard from "examples/Cards/ReviewCards/DefaultReviewCard";

// Images

function Information() {
  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={6}
          justifyContent="center"
          sx={{ mx: "auto", textAlign: "center" }}
        >
          <MKTypography variant="h2" mb={1}>Hear What Our Clients Have to Say</MKTypography>
          <MKTypography variant="body1" color="text">
            Our satisfied clients span the globe - from the United States to every corner of the world. Our dedication to exceptional service knows no bounds.
          </MKTypography>
        </Grid>
        <Grid container spacing={3} sx={{ mt: 8 }}>
          <Grid item xs={12} md={6} lg={4}>
            <DefaultReviewCard
              name="Samantha Lee, Owner of Bluebird Bakery"
              date="January 2023"
              review="Your team helped us build a website that perfectly captured our brand and vision. They were incredibly patient and responsive throughout the process, and we are thrilled with the final product."
              rating={4}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <DefaultReviewCard
              color="info"
              name="Ryan Chen, CEO of Artisanal Furniture Co."
              date="February 2023"
              review="We were impressed with the level of expertise and attention to detail your team brought to the project. They were able to tackle some complex challenges with ease and delivered a solution that exceeded our expectations."
              rating={5}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <DefaultReviewCard
              name="Michelle Patel, Founder of Greenview Landscaping"
              date="March 2023"
              review="Your team helped us streamline our operations with a custom software solution that has saved us countless hours and increased our efficiency. They were communicative and responsive throughout the entire process, and we highly recommend their services."
              rating={4}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
