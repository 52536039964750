// Vertical Timeline component
// eslint-disable-next-line import/no-extraneous-dependencies
import { VerticalTimeline, VerticalTimelineElement } from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import './css/custom-timeline.css';
import MKBox from "../../../components/MKBox";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import MKTypography from "../../../components/MKTypography";

const steps = [
  {
    icon: '💬',
    date: 'Step 1',
    header: 'Consultation',
    body: `In a 15-30 minute meeting, you will tell us your needs while being protected by NDA's. This is the initial meeting to see if we align and want to move forward.`,
  },
  {
    icon: '📆',
    date: 'Step 2',
    header: 'Planning',
    body: 'We work with you to gather more information about your project and create a timeline with estimated hours.',
  },
  {
    icon: '🎨',
    date: 'Step 3',
    header: 'Design',
    body: 'We create best-in-class UI/UX wireframes and give you a prototype to demo for investors. You own everything and can provide granular feedback to maximize development time later.',
  },
  {
    icon: '💻',
    date: 'Step 4',
    header: 'Development',
    body: 'Our team goes to work on your project! We keep you in the loop with weekly progress reports and demos.',
  },
  {
    icon: '🛠️🤝',
    date: 'Step 5',
    header: 'Ongoing Support',
    body: 'Our ongoing support ensures your product runs smoothly, and we\'re always available to make improvements or updates.',
  },
];

function ProcessTimeline() {
  return (
    <MKBox component="section" py={6}>
      <Container>
        <Grid>
          <MKTypography variant="h2" mb={9}>Our Proven Process for Delivering Exceptional Results</MKTypography>
          <VerticalTimeline
            // layout="1-column-left"
            lineColor="#000000"
            animate={true}
          >
            {steps.map((step) => {
              return (
                <VerticalTimelineElement
                  key={step.date}
                  className="vertical-timeline-element"
                  contentStyle={{ background: "#eeeaea", color: "#000000" }}
                  contentArrowStyle={{ borderRight: "7px solid  #eeeaea" }}
                  dateClassName="vertical-timeline-element-date-text"
                  date={step.date}
                  iconClassName="vertical-timeline-element-emoji-icon"
                  icon={<span>{step.icon}</span>}
                >
                  <h3 className="vertical-timeline-element-title">{step.header}</h3>
                  <p>{step.body}</p>
                </VerticalTimelineElement>
              );
            })}
          </VerticalTimeline>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default ProcessTimeline;

/*
LONG DESCRIPTIONS FOR EACH STEP //TODO REMOVE

STEP 1:
At our initial consultation meeting, we will take the time to listen to your unique needs and vision for your project. This meeting will typically take 15-30 minutes and can be conducted via video call or in-person, depending on your preferences. Before the meeting, we will provide a non-disclosure agreement (NDA) for you to sign, ensuring that any sensitive information you share with us will be kept confidential.

During the consultation, we will work to understand your requirements and goals for the project. This will allow us to better advise you on the best course of action to take and provide you with an accurate quote for the work that needs to be done. Our team of experts will be available to answer any questions you may have and to provide guidance on the technical aspects of the project.

The consultation meeting is a chance for us to determine if we are a good fit for each other and if we can move forward to further planning. We value clear communication and transparency throughout the entire process, and we strive to build strong relationships with our clients to ensure their satisfaction with the final product.

STEP 2:
After the consultation, we will work with you to gain a thorough understanding of the scope of the project through a series of meetings. This will allow our team to produce a feature-timeline and estimate of hours required to complete the project. While we cannot guarantee these estimations, they align us all and provide a clear understanding of what to expect moving forward.

STEP 3:
In this step, our design team produces best-in-class UI/UX wireframes for your app. You will receive a prototype to demo for investors and can provide granular feedback to ensure that we lock in with stakeholders as a team. We believe that ownership is important, so you own everything at every step of the process. Giving detailed feedback during this stage is crucial to maximize development time later on.

STEP 4:
In the development phase, our team starts building your project based on the plans and designs agreed upon in the previous stages. You get to sit back and watch us code while we work hard to bring your vision to life. We believe in transparency and communication, so we'll meet with you at least once a week to provide progress reports and demos. This ensures that we're all on the same page and that the project is moving forward according to plan.

STEP 5:
Our commitment to you doesn't end after we deliver the final product. We continue to work closely with you to ensure your app is running smoothly and meeting your expectations. Our ongoing support includes regular check-ins and updates to address any issues that arise. We also provide you with the flexibility to make enhancements or updates to your app as needed, and we'll be there to support you every step of the way. Our goal is to ensure your continued success with your product long after the initial development is complete.


 */