// BlogPost.js

import React from "react";
import { useParams } from "react-router-dom";
import blogPosts from "../BlogPage/data/blogPostsData";
import { Link } from "react-router-dom";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

// Navbar and Footer
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import Footer from "components/Footer";

// page sections
import Information from "../BlogPage/sections/Information";
import FormSimple from "../../layouts/sections/input-areas/forms/components/FormSimple";

// Routes
import routes from "routes";

// Images
import bgImage from "assets/images/bg-clouds-min.jpg";

function BlogPost() {
  const { id } = useParams();

  return (
    <>
      <DefaultNavbar routes={routes} sticky />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: ({
            functions: { linearGradient, rgba },
            palette: { gradients },
          }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
              mb={3}
            >
              {blogPosts[id].title}
            </MKTypography>
            <Grid
              container
              spacing={2}
              direction={{ xs: "column", md: "row" }}
              sx={{ justifyContent: "center" }}
            >
              <Grid item>
                <MKButton
                  color="default"
                  component={Link}
                  to="https://calendly.com/dreamtechlab"
                  target="_blank"
                  size="large"
                  sx={{
                    color: ({ palette: { dark } }) => dark.main,
                    "&:hover": {
                      backgroundColor: ({ palette: { info } }) => info.main,
                      color: ({ palette: { light } }) => light.main,
                    },
                  }}
                >
                  Schedule Consultation
                </MKButton>
              </Grid>
              <Grid item>
                <MKButton
                  color="default"
                  component={Link}
                  to="/contact"
                  size="large"
                  sx={{
                    color: ({ palette: { dark } }) => dark.main,
                    "&:hover": {
                      backgroundColor: ({ palette: { info } }) => info.main,
                      color: ({ palette: { light } }) => light.main,
                    },
                  }}
                >
                  Contact Us
                </MKButton>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Container>
          <Information
            title={blogPosts[id].title}
            bodyTexts={blogPosts[id].bodyTexts}
            date={blogPosts[id].date}
          />
          <FormSimple />
        </Container>
      </Card>
      <Footer />
    </>
  );
}

export default BlogPost;
