const data = [
  {
    title: "Web Application Development",
    description: "At Dream Tech, we specialize in web application development that leverages the latest technologies and frameworks to deliver scalable, high-quality web applications. Our team of experienced developers is proficient in both front-end and back-end technologies, allowing us to create responsive, feature-rich applications that meet your specific needs. We use HTML, CSS, and JavaScript to create dynamic user interfaces, while Node.js, PHP, and Ruby on Rails power the back-end. Our expertise in database technologies such as MySQL, PostgreSQL, and MongoDB allows us to create data-driven web applications that scale with your business.",
  },
  {
    title: "E-commerce Development",
    description: "With the increasing demand for e-commerce websites, our team has extensive experience in e-commerce development. We can create customized online stores using popular e-commerce platforms such as Shopify, WooCommerce, and Magento, allowing you to sell your products and services online. Our team can also help you with payment gateway integration, shipping and tax configurations, and other essential e-commerce features.",
  },
  {
    title: "Content Management Systems",
    description: "At Dream Tech, we can create custom content management systems that enable you to manage your website's content efficiently. We can create CMS using popular platforms such as WordPress, Drupal, and Joomla, which allow you to update your website's content without needing technical expertise. Our team can also create customized CMS that meet your specific business needs.",
  },
  {
    title: "Responsive Web Design",
    description: "With the increasing use of mobile devices, it's crucial to have a responsive website that works seamlessly across all devices. At Dream Tech, we create responsive websites that adapt to different screen sizes and devices, ensuring that your website looks great and functions well on all devices. Our team uses the latest responsive design techniques to create websites that are visually appealing and user-friendly.",
  },
  {
    title: "Web Security and Performance",
    description: "At Dream Tech, we take web security and performance seriously. We use industry-standard security practices to ensure that your website is secure and protected from cyber attacks. Our team can also optimize your website's performance, ensuring that it loads quickly and smoothly. We use techniques such as caching, minification, and CDN to optimize your website's speed and performance.",
  },
];

export default data;