const data = [
  {
    title: "User Research and Analysis",
    description: "We conduct user research and analysis to understand your target audience and their needs. This enables us to design user-centric interfaces that improve usability and provide a seamless user experience.",
  },
  {
    title: "Wireframing and Prototyping",
    description: "Our design team creates wireframes and prototypes to provide a visual representation of the user interface and interactions. This allows us to refine the design before starting development, reducing the risk of costly changes later on.",
  },
  {
    title: "UI and Visual Design",
    description: "Our UI and visual designers create engaging and aesthetically pleasing interfaces that align with your brand's visual identity. We use the latest design trends and technologies to create modern and intuitive interfaces that meet your business needs.",
  },
  {
    title: "Usability Testing",
    description: "We conduct usability testing to validate our designs and ensure that they meet your users' needs. This allows us to identify and fix any issues before launch, resulting in a more successful product.",
  },
  {
    title: "Accessibility and Inclusivity",
    description: "We design with accessibility and inclusivity in mind, ensuring that your product is usable and accessible to everyone, regardless of their abilities. We follow industry standards and guidelines to create products that are accessible and meet the needs of a diverse audience.",
  },
];

export default data;